import React from 'react'
import { Icon, ICON_TYPES } from '@/components/Icon'

/**
 * Preview Icons
 *
 * This page should be exlcuded from sitemap
 */

const IconsPage = () => {
  return (
    <div
      style={{
        paddingBottom: '100px',
      }}
    >
      {Object.keys(ICON_TYPES).map((icon) => {
        return (
          <div
            key={icon}
            style={{
              display: 'flex',
              alignItems: 'center',
              maxWidth: '600px',
              margin: '70px auto',
              backgroundColor: 'white',
              padding: '20px',
            }}
          >
            <div style={{ width: '50%', flexGrow: 0, flexShrink: 0 }}>
              <Icon uid={icon} />
            </div>
            <div style={{ fontSize: '50px', flexGrow: 1, paddingLeft: '50px' }}>
              {icon}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default IconsPage
